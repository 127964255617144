import { SpotterWave } from "./shared-types/SpotterTypes";

import { GradientArray } from "./components/weather/GLWeatherMapLayer/WeatherRenderer/render-color-utils";
import { UserMetadata } from "./contexts/AuthenticationContext";

const stormGlassAPIBase =
  process.env.REACT_APP_STORM_GLASS_BASE_URL ||
  "https://api.sofarocean.com/marine-weather/v1/";

export const stormGlassAPIToken = "23e98566442085e40dfa9bb3ddfc95";

const config = {
  appSettingsDatabaseName: "tell-tale", // do not change this. needs to be the same from build to build
  mapboxStyleUrl: "mapbox://styles/jonsen/ckcgprwic1bxz1is2tvd560pg",
  // mapboxStyleUrl: "mapbox://styles/jonsen/ck10w9t0p056l1do8z0iq14zp",
  mapboxPublicAccessToken:
    "pk.eyJ1Ijoiam9uc2VuIiwiYSI6IkR6UU9oMDQifQ.dymRIgqv-UV6oz0-HCFx1w",

  auth0: {
    // For now we add these here. In the future, we keep them out of the repo
    clientId:
      process.env.REACT_APP_AUTH0_CLIENT_ID ||
      (process.env.NODE_ENV === "production"
        ? "XVjGKxiimEAQd6a4uCwXuB0Fms5HkCBS"
        : "NqpkoRivkXlI1UwHH6GHVnhxVqLrQNJv"),
    domain:
      process.env.REACT_APP_AUTH0_DOMAIN ||
      (process.env.NODE_ENV === "production"
        ? "auth.sofarocean.com"
        : "sofarocean-staging.us.auth0.com"),
    audience:
      process.env.REACT_APP_AUTH0_AUDIENCE ||
      (process.env.NODE_ENV === "production"
        ? "sofar-api-prod"
        : "sofar-api-staging"),
  },

  stormGlassWeatherCubesEndpoint: (
    modelID: string,
    variableID: string,
    initTime: string,
    quadkey: string,
    type: "forecast" | "hindcast" | "analysis" = "forecast",
    version: string = "V0",
    resolution: string = "low"
  ) =>
    `${stormGlassAPIBase}models/${modelID}/${type}/weather-cube/` +
    `${variableID}/${initTime}/${quadkey}?version=${version}&resolution=${resolution}&speak=friend`,

  stormGlassWeatherCubesBatchEndpoint: (
    modelID: string,
    variableID: string,
    initTime: string,
    domain: "global" | "partial",
    quadkeys: string[] = [],
    type: "forecast" | "hindcast" | "analysis" = "forecast",
    version: string = "V0",
    resolution: string = "low"
  ) =>
    `${stormGlassAPIBase}models/${modelID}/${type}/weather-cubes/` +
    `${variableID}/${initTime}/${domain}?version=${version}&resolution=${resolution}&quadkeys=${quadkeys.join(
      ","
    )}&token=${stormGlassAPIToken}&speak=friend`,

  stormGlassInitTimesEndpoint: (
    modelID: string,
    variableIDs: string[],
    queryTime: Date
  ) =>
    `${stormGlassAPIBase}models/` +
    `${modelID}/init-times?dataFormat=weather-cube&availableAt=${queryTime.toISOString()}&` +
    `variableIDs=${variableIDs.join(
      ","
    )}&dataType=forecast&token=${stormGlassAPIToken}&speak=friend`,

  stormGlassDataCoverageEndpoint: (
    modelID: string,
    variableIDs: string[],
    queryTime: Date,
    requestedStartTime: Date,
    requestedEndTime: Date
  ) =>
    `${stormGlassAPIBase}models/${modelID}/data-coverage?` +
    `dataFormat=weather-cube&availableAt=${queryTime.toISOString()}` +
    `&variableIDs=${variableIDs.join(",")}` +
    `&start=${requestedStartTime.toISOString()}&end=${requestedEndTime.toISOString()}` +
    `&token=${stormGlassAPIToken}&speak=friend`,

  spotterWaveDataEndpoint: (
    spotterID: string,
    startDate: string,
    endDate: string
  ) =>
    `https://api.sofarocean.com/oceans-api/wave-data?` +
    `spotterId=${spotterID}&` +
    `startDate=${startDate}&endDate=${endDate}`,

  spotterLatestDataEndpoint:
    "https://api.sofarocean.com/oceans-api/latest-data?includeWindData=true",
  staleSpotterDataAgeMs: 24 * 60 * 60 * 1000, // any spotter data older than 24 hours is not shown
  stormGlassTropicalStormForeCastAPI:
    stormGlassAPIBase + `tropical-storms/forecast?token=${stormGlassAPIToken}`,

  whitelistUrls: [
    `api.sofarocean.com`,
    `sofar-ship-routing.s3.us-west-1.amazonaws.com`,
    `sofar-weather-cubes.s3.amazonaws.com`,
    `storm-glass-prod.herokuapp.com`,
    `sofar-polaris-prod.herokuapp.com`,
    `api.mapbox.com`,
    `www.google-analytics.com`,
    `o504711.ingest.sentry.io`,
    `api-js.mixpanel.com`,
    `cdn.auth0.com`,
    `auth.sofarocean.com`,
  ],

  /** This distance sets the buffer around the route line for which weather will
   *  automatically be downloaded.
   */
  weatherBufferDistanceKm: 2000,

  /** Determine how frequently the service worker should check for new app
   *  versions of the app. The app will always check for updates immediately
   *  after the initial page load— this handles the case where a
   *  long-running tab is left open
   */
  serviceWorkerUpdateFrequencySeconds: 60,

  aisPollIntervalMs: 60 * 1000,

  routePollIntervalMs: 60 * 1000,

  spotterPollIntervalMs: 10 * 60 * 1000,

  userMetadataRetryInterval: 15 * 1000,

  tropicalStormsPollIntervalMs: 10 * 60 * 1000,

  simulatorStepSizeMs: 60 * 60 * 1000,

  dailyRoutingEmailWaypointTimeWindowDurationMs: 48 * 60 * 60 * 1000,

  nearFutureSafetyWarningsTimeWindowDurationMs: 48 * 60 * 60 * 1000,

  aisMaxAgeForComputingTimezoneHours: 3,

  guidanceSendOutTimeHours: 9,

  bannerAutoDismissDelayMs: 7000,

  weatherMapColorGradient: [
    ["#B3D9FB", 0],
    ["#669AED", 20],
    ["#73DDC9", 35],
    ["#ead86a", 55],
    ["#F77C79", 80],
    ["#E25685", 90],
    ["#7a100e", 100],
  ] as GradientArray,

  plotDropdownLabels: [
    {
      label: "Height",
      value: "wave-height",
      parent: "Wave",
      magnitudeUnits: "m",
      displayUnits: "m",
      magnitudeMin: 0,
      magnitudeMax: 10,
      variables: [
        {
          modelID: "SofarECMWFHResOperationalWaveModel",
          displayName: "Significant Height",
          variableID: "significantWaveHeight",
          spotterVariableID: "significantWaveHeight",
        },
      ],
    },
    {
      label: "Direction",
      value: "wave-direction",
      parent: "Wave",
      magnitudeUnits: "°",
      displayUnits: "°",
      magnitudeMin: 0,
      magnitudeMax: 360,
      variables: [
        {
          modelID: "SofarECMWFHResOperationalWaveModel",
          displayName: "Mean Direction",
          variableID: "meanDirection",
          spotterVariableID: "meanDirection",
        },
        {
          modelID: "SofarECMWFHResOperationalWaveModel",
          displayName: "Peak Direction",
          variableID: "peakDirection",
          spotterVariableID: "peakDirection",
        },
      ],
    },
    {
      label: "Period",
      value: "wave-period",
      parent: "Wave",
      magnitudeUnits: "s",
      displayUnits: "s",
      magnitudeMin: 2,
      magnitudeMax: 20,
      variables: [
        {
          modelID: "SofarECMWFHResOperationalWaveModel",
          displayName: "Mean Period",
          variableID: "meanPeriod",
          spotterVariableID: "meanPeriod",
        },
        {
          modelID: "SofarECMWFHResOperationalWaveModel",
          displayName: "Peak Period",
          variableID: "peakFrequency",
          spotterVariableID: "peakPeriod",
          magnitudeUnits: "Hz",
          displayUnits: "s",
        },
      ],
    },
    {
      label: "Height",
      value: "wave-height-wind-sea",
      parent: "Sea",
      magnitudeUnits: "m",
      displayUnits: "m",
      magnitudeMin: 0,
      magnitudeMax: 10,
      variables: [
        {
          modelID: "SofarECMWFHResOperationalWaveModel",
          displayName: "Significant Height",
          variableID: "significantWaveHeightWindWaves",
        },
      ],
    },
    // {
    //   label: "Direction",
    //   value: "wave-direction-wind-sea",
    //   parent: "Sea",
    //   magnitudeUnits: "°",
    //   displayUnits: "°",
    //   magnitudeMin: 0,
    //   magnitudeMax: 360,
    //   variables: [
    //     {
    //       modelID: "SofarECMWFHResOperationalWaveModel",
    //       displayName: "Mean Direction",
    //       variableID: "meanDirectionWindWaves",
    //     },
    //   ],
    // },
    // {
    //   label: "Period",
    //   value: "wave-period-wind-sea",
    //   parent: "sea",
    //   magnitudeUnits: "s",
    //   displayUnits: "s",
    //   magnitudeMin: 2,
    //   magnitudeMax: 12,
    //   variables: [
    //     {
    //       modelID: "SofarECMWFHResOperationalWaveModel",
    //       displayName: "Peak Wave Period of Wind Sea",
    //       variableID: "peakPeriodWindWaves",
    //     },
    //   ],
    // },
    {
      label: "Height",
      value: "wave-height-swell",
      parent: "Swell",
      magnitudeUnits: "m",
      displayUnits: "m",
      magnitudeMin: 0,
      magnitudeMax: 5,
      variables: [
        {
          modelID: "SofarECMWFHResOperationalWaveModel",
          displayName: "Significant Height",
          variableID: "significantWaveHeightFirstSwell",
        },
      ],
    },
  ] as PlotDropdownLabels[],

  weatherMapVariables: {
    combinedWaves: {
      modelID: "SofarECMWFHResOperationalWaveModel",
      magnitudeVariableID: "significantWaveHeight",
      directionVariableID: "meanDirection",
      type: "magnitude-direction",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 10, mapLayer: 10 },
      magnitudeUnits: "m",
      displayUnits: "m",
      displayName: "Wave Height",
    },
    currents: {
      modelID: "MercatorOcean",
      eastingVariableID: "surfaceCurrentVelocityEastward",
      northingVariableID: "surfaceCurrentVelocityNorthward",
      type: "vector-components",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 1.5, mapLayer: 1.5 },
      magnitudeUnits: "m/s",
      displayUnits: "kts",
      displayName: "Currents",
    },
    wind: {
      modelID: "ECMWFHRes",
      eastingVariableID: "windVelocity10MeterEastward",
      northingVariableID: "windVelocity10MeterNorthward",
      type: "vector-components",
      magnitudeMin: { plot: 0, mapLayer: 0 },
      magnitudeMax: { plot: 30.8667, mapLayer: 30.8667 },
      magnitudeUnits: "m/s",
      displayUnits: "kts",
      displayName: "Wind",
    },
    barometricPressure: {
      modelID: "ECMWFHRes",
      variableID: "meanSeaLevelPressure",
      type: "scalar",
      magnitudeMin: { plot: 95000, mapLayer: 80000 },
      magnitudeMax: { plot: 105000, mapLayer: 105000 },
      magnitudeUnits: "Pa",
      displayUnits: "mbar",
      displayName: "Pressure",
    },
  } as WeatherVariableConfiguration,

  analytics: {
    trackingID: "UA-136244537-5",
    debug: false,
    mixpanelProjectTokens: {
      prod: "d9fa0dc744a76e346294b0fd2548adf9",
      staging: "692d4d338f7c1dd2b81ec19149d0f9a6",
      dev: "c651fcb31fef1484811c3eab96a6a135",
    },
  } as AnalyticsConfiguration,

  defaultUserMetadata: {
    vesselUuid: null,
    accountType: "single" as const,
    roles: [],
  } as UserMetadata,

  /** The identifier used for this project's error reporting on sentry.io */
  sentryDSN:
    "https://3cc843f39ba047f8baf35a22ebeb46b4@o504711.ingest.sentry.io/5600986",
  hasApiUrlOverride: Boolean(process.env.REACT_APP_CRYSTAL_GLOBE_BASE_URL),
};

export type WeatherPlotVariables =
  | "meanDirection"
  | "meanDirectionWindWaves"
  | "meanDirectionFirstSwell"
  | "meanPeriod"
  | "significantWaveHeight"
  | "significantWaveHeightWindWaves"
  | "significantWaveHeightFirstSwell"
  | "peakFrequency"
  | "peakDirection"
  | "peakPeriodFirstSwell"
  | "peakPeriodWindWaves";

export type WeatherPlotVariableConfig = {
  modelID: string;
  displayName: string;
  variableID: WeatherPlotVariables;
  spotterVariableID?: Omit<keyof SpotterWave, "timestamp">;
  magnitudeUnits?: WeatherVariableUnit;
  displayUnits?: WeatherVariableUnit;
};

export type PlotValueTypes =
  | "wave-height"
  | "wave-direction"
  | "wave-period"
  | "wave-height-wind-sea"
  | "wave-direction-wind-sea"
  | "wave-period-wind-sea"
  | "wave-height-swell";

export const PlotParentValues = ["Wave", "Sea", "Swell"] as const;
export type PlotParentTypes = typeof PlotParentValues[number];

export type PlotDropdownLabels = {
  label: string;
  value: PlotValueTypes;
  parent: PlotParentTypes;
  magnitudeUnits: WeatherVariableUnit;
  displayUnits: WeatherVariableUnit;
  magnitudeMin: number;
  magnitudeMax: number;
  variables: WeatherPlotVariableConfig[];
};

export type WeatherVariableUnit =
  | "m/s"
  | "kts"
  | "s"
  | "m"
  | "Pa"
  | "mbar"
  | "Hz"
  | "°";

export type WeatherQuantities =
  | "seas"
  | "swell"
  | "combinedWaves"
  | "swellPeriod"
  | "peakWaveFrequency"
  | "currents"
  | "wind"
  | "barometricPressure";
export type WeatherVariableConfiguration = Record<
  WeatherQuantities,
  CompositeWeatherVariableDefinition
>;

export type WeatherVariableDefinition = {
  modelID: string;
  /** Minimum expected value to use when visualizing data */
  magnitudeMin: {
    plot: number;
    mapLayer: number;
  };
  /** Maximum expected value to use when visualizing data */
  magnitudeMax: {
    plot: number;
    mapLayer: number;
  };
  magnitudeUnits: WeatherVariableUnit;
  /** Units to display values in on screen **/
  displayUnits: WeatherVariableUnit;
  displayName: string;
};

export type MagnitudeDirectionCompositeDefinition = {
  type: "magnitude-direction";
  magnitudeVariableID: string;
  directionVariableID: string;
} & WeatherVariableDefinition;
export type VectorComponentsCompositeDefinition = {
  type: "vector-components";
  eastingVariableID: string;
  northingVariableID: string;
} & WeatherVariableDefinition;
export type ScalarCompositeDefinition = {
  type: "scalar";
  variableID: string;
} & WeatherVariableDefinition;
export type CompositeWeatherVariableDefinition =
  | MagnitudeDirectionCompositeDefinition
  | VectorComponentsCompositeDefinition
  | ScalarCompositeDefinition;

export type AnalyticsConfiguration = {
  trackingID: string;
  debug: boolean;
  mixpanelProjectTokens: { dev: string; prod: string; staging: string };
};

export default config;
