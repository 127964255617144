import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./styles/fonts.ts";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5W6TXDM5",
};

TagManager.initialize(tagManagerArgs);

// You probably don't want to add things here unless they have to do with
// configuring React or the ServiceWorker. Add new contexts, etc to the
// `WeatherApp` component or one of its descendents.
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
