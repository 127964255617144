import * as React from "react";
import {
  ErrorContextProvider,
  ErrorContextType,
} from "../../../contexts/ErrorContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorDialog from "./ErrorDialog";

export type GlobalErrorHandlerProps = {
  state: ErrorContextType;
};

const getResetFunction = (resetErrorBoundary: () => void) => {
  return () => {
    resetErrorBoundary();
    window.document.location.replace("/");
  };
};

const errorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => {
  return (
    <ErrorDialog
      showDetails={true}
      showTitle={true}
      error={error}
      reloadPage={getResetFunction(resetErrorBoundary)}
    />
  );
};

/**
 * Global error handler that displays uncaught errors from react or
 * errors that where set via @see ErrorContext.
 *
 */
const GlobalErrorHandler: React.FC<GlobalErrorHandlerProps> = ({
  children,
  state,
}) => {
  return (
    <ErrorBoundary FallbackComponent={errorFallback}>
      <ErrorContextProvider value={state}>{children}</ErrorContextProvider>
    </ErrorBoundary>
  );
};

export default GlobalErrorHandler;
