import * as React from "react";
import { ErrorModal } from "./ErrorModal";

export type ErrorDialogProps = {
  showTitle: boolean;
  showDetails: boolean;
  description?: string | React.ReactElement;
  error: Error | undefined;
  dismissError?: () => void;
  reloadPage?: () => void;
};

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  children,
  showTitle,
  showDetails,
  description,
  error,
  dismissError,
  reloadPage,
}) => {
  return (
    <>
      {children}
      <ErrorModal
        showTitle={showTitle}
        showDetails={showDetails}
        infoMessage={
          description ??
          "Something went wrong and the Weather Dashboard needs to be reloaded."
        }
        error={error}
        canDismiss={dismissError !== undefined}
        canReload={dismissError === undefined}
        dismissError={dismissError}
        reloadPage={reloadPage}
      />
    </>
  );
};

export default ErrorDialog;
