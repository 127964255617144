import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { ReactElement } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export type ErrorModalProps = {
  showTitle: boolean;
  showDetails: boolean;
  infoMessage: string | ReactElement;
  error: Error | undefined;
  canDismiss?: boolean;
  canReload?: boolean;
  dismissError?: () => void | undefined;
  reloadPage?: () => void | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    code: { whiteSpace: "pre-wrap", maxHeight: 300, overflowY: "auto" },
  })
);

/** Error modal component for displaying errors directly to the user.
 *
 *  Note: It might feel natural to add Sentry.io logging into this component but
 *  it seems like that typically results in errors being reported twice: once
 *  when they are thrown and/or logged with console.error and again when this
 *  component mounts. So don't worry about it here and just use `console.error`
 *  if you want an error to be reported back to the mothership!
 */
export const ErrorModal: React.FC<ErrorModalProps> = ({
  showTitle,
  showDetails,
  infoMessage,
  error,
  canDismiss = true,
  canReload = true,
  dismissError = undefined,
  reloadPage = undefined,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={!!error} fullWidth={true} maxWidth={"sm"}>
      {showTitle && <DialogTitle>Error</DialogTitle>}
      <DialogContent>
        <DialogContentText>{infoMessage}</DialogContentText>
        {showDetails && (
          <DialogContentText variant="subtitle2">
            Details
            <div>
              <code className={classes.code}>{error?.message}</code>
            </div>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {canDismiss && (
          <Button onClick={dismissError} color="primary" autoFocus>
            OK
          </Button>
        )}
        {canReload && (
          <Button onClick={reloadPage} color="primary" autoFocus>
            Restart Weather
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
