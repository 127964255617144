import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";

import { extendedPalette, typographyStyles } from "../../../styles/theme";

import logo from "../../../bundle-data/images/loading-graphic.png";
import logoMobile from "../../../bundle-data/images/loading-graphic-mobile.png";
import logomark from "../../../bundle-data/images/SOFAR-no-text.png";

export const useStyles = makeStyles((theme) =>
  createStyles({
    fullscreen: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      backgroundColor: extendedPalette.neutralWhisper,
      padding: "10vh 5vw",
      ...typographyStyles.loadingScreen,

      [theme.breakpoints.down("xs")]: {
        padding: "5vh 5vw 15vh",
      },
    },
    flexWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    line: {
      borderTop: `1px solid ${extendedPalette.navy}`,
      margin: `0 ${theme.spacing(4)}px`,
      flex: 1,
      [theme.breakpoints.down("xs")]: {
        margin: `0 ${theme.spacing(2)}px`,
      },
    },
    lineBlue: {
      borderColor: extendedPalette.sofarBlue,
    },
    pageTitle: {
      color: extendedPalette.sofarBlue,
    },
    logo: {
      display: "block",
      width: "90vw",
      paddingBottom: "33%",
      backgroundImage: `url(${logo})`,
      backgroundSize: "contain",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${logoMobile})`,
        width: "90vw",
        paddingBottom: "70%",
      },
    },
    sofarNoText: {
      width: "40px",
    },
    mobileWarning: {
      textAlign: "center",
      marginTop: "30px",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    noMobileDisplay: {
      [theme.breakpoints.down("xs")]: {
        visibility: "hidden",
      },
    },
    loading: {
      animation: `$loading 3s infinite ease`,
    },
    "@keyframes loading": {
      "0%": { opacity: 1 },
      "50%": { opacity: 0.25 },
      "100%": { opacity: 1 },
    },
  })
);

const FallbackLoadingScreen: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.fullscreen}>
      <div className={classes.flexWrapper}>
        <img src={logomark} alt="Sofar Icon" className={classes.sofarNoText} />
        <div className={`${classes.line} ${classes.lineBlue}`} />
        <div className={classes.pageTitle}>Weather Dashboard</div>
      </div>

      <div>
        <div className={classes.logo} />

        <div className={classes.mobileWarning}>
          For optimal experience, please view the Sofar Weather Dashboard on a
          desktop or laptop computer.
        </div>
      </div>

      <div className={`${classes.flexWrapper} ${classes.noMobileDisplay}`}>
        <div className={classes.line} style={{ marginLeft: 0 }} />
        <div className={classes.loading}>Loading</div>
        <div className={classes.line} style={{ marginRight: 0 }} />
      </div>
    </div>
  );
};
export default FallbackLoadingScreen;
