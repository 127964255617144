import { createTheme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const fontFamilies = {
  gilroy: `"Gilroy", "Arial", sans-serif`,
  roboto: `"Roboto", "Arial", sans-serif`,
  robotoMono: `"Roboto Mono", "Courier New", monospaced`,
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#096ED3",
    },
    secondary: {
      main: "#262A3F",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: fontFamilies.gilroy,
    body1: {
      lineHeight: "160%",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "$labelcolor",
        },
      },
    },
  },
});

export const extendedPalette = {
  sofarBlue: "#096ED3",
  neutralDark: "#677E98",
  neutralSlightlyDark: "#778B9E",
  neutral: "#899AAD",
  neutralMedDark: "#A8B4C1",
  neutralMedium: "#C4CCD6",
  neutralMedLight: "#D6DCE2",
  neutralLight: "#E5E9ED",
  neutralWhisper: "#F3F5F7",
  headerLightBlue: "#E5F3FE",
  navy: "#262A3F",
  sea: "#AADAFF",
  seaLight: "#d4ecff", // sea at 0.5 opacity
  ocean: "#0050A0",
  red: "#FF6666",
  spotter: {
    main: "#EFCF16",
  },
  weather: {
    main: "#262A3F",
  },
  comparisonRed: "#FF6666",
  comparisonGreen: "#028C72",
  tropicalStorms: {
    stormLine: "#FF6666",
    windDangerFill: "rgba(249,214,143, 0.25)", //#F9D68F 25%
    windDangerOutline: "#F9D68F",
    coneOfUncertaintyFill: "rgba(255,255,255, 0.4)",
    coneOfUncertaintyOutline: "#FFFFFF",
    windGt64Fill: "rgba(255, 102, 102, 0.25)",
    windGt64Outline: "#FF6666",
    wind49To63Fill: "rgba(255, 165, 51, 0.25)",
    wind49To63Outline: "#FFA533",
    wind34To49Fill: "rgba(249,214,143, 0.25)",
    wind34To49Outline: "#F9D68F",
  },
};

// Gilroy is set as the default font in fonts.css

export const typographyStyles = {
  loadingScreen: {
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.36px", //2%
  },
  header: {
    fontSize: "18px",
    letterSpacing: "0.36px", //2%
  },
  subHeader: {
    fontSize: "16px",
    lineHeight: "20.8px",
    letterSpacing: "0.32px", //2%
  },
  bodyDense: {
    fontSize: "16px",
    lineHeight: "21.8px",
  },
  bodySpaced: {
    fontSize: "14px",
    lineHeight: "26.6px",
  },
  formLabel: {
    fontSize: "14px",
    lineHeight: "16.06px",
    letterSpacing: "0.25px", //2%
  },
  formSmall: {
    fontSize: "13px",
    lineHeight: "16.9px",
    letterSpacing: "0.26px", //2%
  },
  sidebarCardTitle: {
    fontSize: "12px",
    lineHeight: "22.8px",
    letterSpacing: "0.36px", //3%
  },
  label: {
    fontSize: "10px",
    lineHeight: "13px",
    letterSpacing: "0.3px", //3%
  },
  sidebarParagraph: {
    fontFamily: fontFamilies.roboto,
    fontSize: "14px",
    lineHeight: "21px",
  },
  monospaced: {
    fontFamily: fontFamilies.robotoMono,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0.24px", //2%
  },
};
export const useAppTypographyStyles = makeStyles(typographyStyles);
