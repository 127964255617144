import React, { Suspense, useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

import FallbackLoadingScreen from "../screens/FallbackLoadingScreen";
import GlobalErrorHandler from "./GlobalErrorHandler";
import ErrorDialog from "./GlobalErrorHandler/ErrorDialog";
import useErrorState from "../../contexts/ErrorContext/use-error-state";

import * as registerServiceWorker from "../../service-worker/register";
import { ServiceWorkerStatus } from "../../service-worker/register";
import config from "../../config";
import { QueryParamProvider } from "use-query-params";
import { Route as DomRoute } from "react-router-dom";
import { createBrowserHistory } from "history";

import buildCommit from "../../bundle-data/build-commit.json";
// import { AuthenticationGuard } from "../AuthenticationGuard";
import { Integration } from "@sentry/types";
import { RouterSwitch } from "./RouterSwitch";

const history = createBrowserHistory();
const integrations: Integration[] = [
  new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  }),
];
if (window.location.hostname !== "localhost")
  integrations.push(new CaptureConsole({ levels: ["error"] }));
Sentry.init({
  dsn: config.sentryDSN,
  autoSessionTracking: true,
  integrations,
  tracesSampleRate: 1.0,
  release: "weather-frontend@" + buildCommit.sha1,
  environment: window.location.hostname,
  maxBreadcrumbs: 50,
});

// Split out vendors and app
// const VendorContextProvider = React.lazy(
//   () => import("../../contexts/VendorContext")
// );
const WeatherAppStateManager = React.lazy(
  () => import("./WeatherAppStateManager")
);

// Shared cache used to store results from all react-query queries
const queryCache = new QueryCache();

const App: React.FC<{}> = () => {
  const [serviceWorkerStatus, setServiceWorkerStatus] =
    useState<ServiceWorkerStatus>({
      hasCheckedForUpdate: false,
      isNewAppReady: false,
      isNewAppPublished: false,
      needsRefresh: false,
      autoRefreshOnUpdate: false,
      updateNow: () => window.location.reload(),
    });
  useEffect(() => {
    // Register the service worker and regularly check for updates
    registerServiceWorker.register(
      setServiceWorkerStatus,
      config.serviceWorkerUpdateFrequencySeconds
    );

    // stop trackpad pinching from scaling the page
    document.addEventListener(
      "wheel",
      (e) => {
        if (e.ctrlKey) {
          // only set on pinches, not scrolls
          e.preventDefault();
        }
      },
      { passive: false }
    );
  }, []);
  const errorState = useErrorState();
  const {
    showTitle: showErrorTitle,
    showDetails: showErrorDetails,
    error,
    description,
    onDismissError,
  } = errorState;

  /*
    <VendorContextProvider>
      <AuthenticationGuard>
        weatherappstatemanager
      </AuthenticationGuard>
    </VendorContextProvider>
  */

  let content = <FallbackLoadingScreen />;

  if (
    !/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    // for now, only render the site if we are on a desktop browser
    content = (
      <WeatherAppStateManager serviceWorkerStatus={serviceWorkerStatus}>
        <RouterSwitch />
        <ErrorDialog
          showTitle={showErrorTitle}
          showDetails={showErrorDetails}
          error={error}
          description={description}
          dismissError={onDismissError}
        />
      </WeatherAppStateManager>
    );
  }

  return (
    <GlobalErrorHandler state={errorState}>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={DomRoute}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <Suspense fallback={<FallbackLoadingScreen />}>{content}</Suspense>
          </ReactQueryCacheProvider>
        </QueryParamProvider>
      </Router>
    </GlobalErrorHandler>
  );
};

export default App;
