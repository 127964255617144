import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
// import { AuthenticationScreen } from "../screens/AuthenticationScreen";

// Bundle splitting time. Split out screens that may not be used
const WeatherScreen = React.lazy(() => import("../screens/WeatherScreen"));
const OfflineDebugScreen = React.lazy(
  () => import("../screens/OfflineDebugScreen")
);

/*
  <Route path="/authenticate">
    <AuthenticationScreen />
  </Route>
*/

const AdminRedirect = () => {
  useEffect(() => {
    window.location.href = "https://api-token.sofarocean.com/";
  }, []);

  return null;
};

export const RouterSwitch = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <WeatherScreen />
      </Route>
      <Route path="/debug">
        <OfflineDebugScreen />
      </Route>
      <Route path="/admin">
        <AdminRedirect />
      </Route>
    </Switch>
  );
};
