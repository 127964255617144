// This is a modified version of the create-react-app service worker
// registration script. It automatically reloads the app when a new service
// worker is avaialable.
//
// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities

import { Workbox } from "workbox-window";

/** This object type contains summarized information about the current service
 *  worker lifecycle abd is intended to be passed down to the React app so that
 *  the UI can be updated to prompt
 */
export type ServiceWorkerStatus = {
  /** Flag set to `true` once the app has successfully checked for any updates
   *  to the service worker at all. Could be used to avoid loading content until
   *  one update has been performed.
   */
  hasCheckedForUpdate: boolean;

  /** NOT CURRENTLY USED! Set to `true` if a new version of the app has been
   *  published and is being automatically downloaded in the background.
   */
  isNewAppPublished: boolean;

  /** Set to `true` if a new version of the app is available, downloaded, and
   *  ready to activate. */
  isNewAppReady: boolean;

  /** Callback to actually perform an app update. Only set when
   *  `updateAvailable === true`. If the callback is called with `autoRefresh`
   *  set to `true`, the window will be automatically reloaded after activating
   *  the new service worker.
   */
  updateNow?: (autoRefresh: boolean) => void;

  autoRefreshOnUpdate: boolean;

  /** Set to `true` if the app needs to be refreshed because it is running an
   *  out-of-date service worker. UI might display a modal or automatically
   *  refresh in this case, but it should not allow the user to continue work
   *  without refreshing.
   */
  needsRefresh: boolean;
};

let unclaimed = false;

let status: ServiceWorkerStatus = {
  hasCheckedForUpdate: false,
  isNewAppReady: false,
  isNewAppPublished: false,
  updateNow: undefined,
  needsRefresh: false,
  autoRefreshOnUpdate: false,
};

const SERVICE_WORKER_URL = `${process.env.PUBLIC_URL}/tell-tale-service-worker.js`; // do not change this between builds

export async function register(
  onStatusUpdate?: (newStatus: ServiceWorkerStatus) => void,
  checkForUpdateFrequencySeconds: number = 60
) {
  if (!("serviceWorker" in navigator)) {
    console.warn(
      "Service workers are not supported. App will not be available offline."
    );
    return;
  }

  const setStatus = (newStatus: ServiceWorkerStatus) => {
    status = newStatus;
    onStatusUpdate?.(status);
  };

  const wb = new Workbox(SERVICE_WORKER_URL);
  let registration: ServiceWorkerRegistration | undefined;

  wb.addEventListener("installed", (event) => {
    if (!event.isUpdate) {
      // Service worker has been installed for the first time
      cacheAllLoadedResources(wb);
    }
  });

  // Handle a "waiting" Service Worker. SW's enter the "waiting" state when they
  // are all ready to be activated but have not yet been granted permission to
  // do so because another SW is currently active.
  //
  // In this function, we set some state and create a callback that gets passed
  // down to the UI so that the user or main React app can decide when to
  // install the new version of the app.
  //
  // Currently, this immediately calls the `acceptNewSWCallback` to simplify
  // things as we have not yet built out UI to accept an update and want all
  // users to be running the latest version of the app.
  const handleWaitingServiceWorker = () => {
    const acceptNewSWCallback = (autoRefresh: boolean = true) => {
      // Send a message to the waiting SW to force-skip waiting
      registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
      setStatus({ ...status, autoRefreshOnUpdate: autoRefresh });
    };

    // Update the status state that gets sent down to the React app
    setStatus({
      ...status,
      isNewAppReady: true,
      updateNow: acceptNewSWCallback,
    });

    // Activate the new service work immediately.
    // TODO: This should be triggered in the UI instead of automatically
    // called here once we build out the appropriate UI elements
    acceptNewSWCallback();
  };
  // Service workers enter the "waiting" stage once they are ready to be
  // activated but cannot do so automatically since another service worker is
  // already active.
  wb.addEventListener("waiting", handleWaitingServiceWorker);
  wb.addEventListener("externalwaiting", handleWaitingServiceWorker);

  wb.addEventListener("activated", (event) => {
    if (!event.isUpdate) {
      // This is a brand new service worker install. Need to claim clients,
      // but also we don't wan't to trigger a reload (which is what the
      // `unclaimed` flag prevents)
      unclaimed = true;
      wb.messageSW({ type: "CLAIM_CLIENTS" });
    }
  });

  navigator.serviceWorker.addEventListener("controllerchange", (ev) => {
    if (!unclaimed) {
      // New service worker is all set up. The page needs to be refreshed.
      if (status.autoRefreshOnUpdate) {
        window.location.reload();
      }
      setStatus({ ...status, needsRefresh: true });
    } else {
      // Don't reload if this is the first service worker ever to activate.
      // This happens on the initial app page load.
      unclaimed = false;
    }
  });

  try {
    await checkValidServiceWorker(SERVICE_WORKER_URL);
    registration = await wb.register();

    if (registration?.waiting) {
      // Loaded into a page with a waiting service worker
      handleWaitingServiceWorker();
    }

    wb.update().then(() => {
      // Guaranteed to have checked for at least 1 update at this point
      setStatus({ ...status, hasCheckedForUpdate: true });
    });

    // Check for updates to the service worker on a regular interval
    window.setInterval(async () => {
      try {
        await wb.update();
      } catch (e) {
        // App must be offline — can't automatically check for app updates
      }
    }, 1000 * checkForUpdateFrequencySeconds); // Every 5 seconds
  } catch (e) {
    console.warn(
      "No service worker loaded. Offline support and caching is disabled.\n" +
        "This is normal when running the live-reloading local development server.\n" +
        "Create a production build to test offline features. "
    );
  }
}

/** Ask the service worker to cache all resources that have already been
 *  loaded on the page according its cache rules. This is useful to help the
 *  service worker "catch up" on any requests it might have missed before
 *  being loaded the first time. This should _not_ actually trigger any new
 *  network requests.
 */
function cacheAllLoadedResources(wb: Workbox) {
  // Get the current page URL + all resources the page loaded.
  const urlsToCache = [
    window.location.href,
    ...performance.getEntriesByType("resource").map((r) => r.name),
  ];
  // Send that list of URLs to your router in the service worker.
  wb.messageSW({
    type: "CACHE_URLS",
    payload: { urlsToCache },
  });
}

/** Check if a valid serviceworker script exists. It will not exist when
 *  running the local development server.
 */
async function checkValidServiceWorker(swUrl: string) {
  try {
    const response = await fetch(swUrl, {
      headers: { "Service-Worker": "script" },
    });

    const contentType = response.headers.get("content-type");
    if (
      response.status === 404 ||
      (contentType != null && contentType.indexOf("javascript") === -1)
    ) {
      // No serviceworker found
      throw new Error(
        "Could not find ServiceWorker. This is normal when running the live-reloading development server."
      );
    } else {
      // Service worker found. Proceed as normal.
      return swUrl;
    }
  } catch (e) {
    throw e;
  }
}
